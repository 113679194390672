// src/components/Auth.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigation = useNavigate()
    // Handle login
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/registration/login', { email, password });
            toast.success('Login successful!');
            setTimeout(() => {
                navigation("/login")
            }, 300);
        } catch (error) {
            console.log("error", error);
            console.log("error", password);

            toast.error('Invalid credentials!');
        }
    };

    // Handle forgot password request
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/registration/forgotPassword', { email });
            toast.success('OTP sent to your email!');
            setShowForgotPassword(false); // Close forgot password form
        } catch (error) {
            toast.error('User not found!');
        }
    };

    // Handle password reset
    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/registration/resetPassword', { email, otp, newPassword });
            toast.success('Password reset successfully!');
            setShowForgotPassword(false); // Close forgot password form
        } catch (error) {
            toast.error('Invalid or expired OTP!');
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{showForgotPassword ? 'Reset Password' : 'Login'}</h1>
            {!showForgotPassword ? (
                <form onSubmit={handleLogin}>
                    <div className="form-group py-1">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group py-1">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                    <p className="mt-2">
                        <a href="#!" onClick={() => setShowForgotPassword(true)}>Forgot Password?</a>
                    </p>
                </form>
            ) : (
                <form onSubmit={handleForgotPassword}>
                    <div className="form-group py-1">
                        <label htmlFor="forgotEmail">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="forgotEmail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Send OTP</button>
                    <button type="button" className="btn btn-secondary mt-2" onClick={() => setShowForgotPassword(false)}>Back to Login</button>
                </form>
            )}

            {showForgotPassword && (
                <form onSubmit={handleResetPassword}>
                    <div className="form-group py-1">
                        <label htmlFor="otp">OTP</label>
                        <input
                            type="text"
                            className="form-control"
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group py-1">
                        <label htmlFor="newPassword">New Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Reset Password</button>
                </form>
            )}
        </div>
    );
};

export default Auth;
