import React, { Fragment, useState, useEffect } from 'react';
import { ReactLenis } from '@studio-freight/react-lenis';
import Header from '../../components/header/Header';
import About from '../../components/about/about';
import FunFactSection from '../../components/FunFact/FunFact';
import BlogSection from '../../components/BlogSection/BlogSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import ContactArea from '../../components/ContactArea';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import axios from 'axios';

// Loader Component
const Loader = () => (
    <div className="loader">
        <div className="bouncing-loader">
            <div className="bouncing-ball"></div>
            <div className="bouncing-ball"></div>
            <div className="bouncing-ball"></div>
        </div>
    </div>
);

const HomePage = () => {
    const [profile, setProfile] = useState({});
    const [Tital, setTital] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getHedings = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/heding/hedings', {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.error || 'Error fetching hedings');
        }
    };
    const fetchHedings = async () => {
        try {
            const data = await getHedings();
            console.log("data", data);
            setTital(data)

        } catch (error) {
            setError(error.message);
        }
    };

    const fetchProfile = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/profile');
            if (response.data) {
                console.log("response.data", response.data);
                setProfile(response.data[0]);
            }
        } catch (error) {
            console.error("Error fetching profile data", error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
        fetchHedings();
    }, []);

    const [switchActive, setSwitchActiveState] = useState(
        JSON.parse(localStorage.getItem("switch"))
    );

    const switcherHandler = () => {
        setSwitchActiveState(!switchActive);
        localStorage.setItem("switch", !switchActive);
    };

    useEffect(() => {
        if (switchActive) {
            document.body.classList.add(`dark`);
            document.body.classList.remove(`light`);
        } else {
            document.body.classList.add(`light`);
            document.body.classList.remove(`dark`);
        }
    }, [switchActive]);

    return (
        <Fragment>
            <Header />
            {loading ? (
                <Loader />
            ) : error ? (
                <div className="error-message">
                    <p>Failed to load profile data. Please try again later.</p>
                </div>
            ) : (
                <div className="page-content">
                    <About profile={profile} />
                    <ProjectSection profileData={profile} Tital={Tital} />
                    <ContactArea profile={profile} Tital={Tital} />
                    <Footer profileData={profile} />
                </div>
            )}
            <Scrollbar />
            <div className="switch-btn-wrap">
                <button onClick={switcherHandler} className="switch-btn" data-tooltip-id="switch" data-tooltip-content="Color Switcher">
                    <i className={`${switchActive ? "ti-shine" : "ti-star"}`}></i>
                </button>
            </div>
        </Fragment>
    );
};

export default HomePage;
