// src/components/HedingList.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const HedingList = () => {
    const [hedings, setHedings] = useState([]);
    const [error, setError] = useState(null);

    const getHedings = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/heding/hedings', {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.error || 'Error fetching hedings');
        }
    };

    const deleteHeding = async (id) => {
        try {
            await axios.delete(`https://node-setup-project-production.up.railway.app/api/heding/hedings/${id}`, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            throw new Error(error.response?.data?.error || 'Error deleting heding');
        }
    };

    useEffect(() => {
        const fetchHedings = async () => {
            try {
                const data = await getHedings();
                setHedings(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchHedings();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteHeding(id);
            setHedings(hedings.filter(heding => heding._id !== id));
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container mt-5">
            <ul className="list-group">
                {hedings.map(heding => (
                    <li key={heding._id} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{heding.tital} - {heding.subtital}</span>
                        <span>{heding.type}</span>
                        <button onClick={() => handleDelete(heding._id)} className="btn btn-danger btn-sm">Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HedingList;
