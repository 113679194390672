import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import Dashboard from '../Dashboard/Dashboard';
import Auth from '../Auth/Auth';
import { ToastContainer } from 'react-toastify';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Auth" element={<Auth />} />
          <Route path="/login" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
