// src/components/ActivityManager.js
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';

const ActivityManager = ({ profile, setProfile }) => {
    const [activities, setActivities] = useState(profile.activity || []);
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [editId, setEditId] = useState(null);
    useEffect(() => {
        setActivities(profile.activity)
    }, [profile])

    // Add or Update Activity
    const handleSave = async () => {
        try {
            let updatedActivities;

            if (editId !== null) {
                // Update existing activity
                updatedActivities = activities.map(activity =>
                    activity.id === editId ? { ...activity, name, value } : activity
                );
                toast.success('Data updated successfully!');
            } else {
                // Add new activity
                updatedActivities = [...activities, { id: Date.now(), name, value }];
                toast.success('Data added successfully!');
            }

            // Update state
            setActivities(updatedActivities);
            setProfile({ ...profile, activity: updatedActivities });

            // Call API to update profile with new activity list
            await axios.put(`https://node-setup-project-production.up.railway.app/api/profile/${profile._id}`, { ...profile, activity: updatedActivities });

            // Reset form fields
            setName('');
            setValue('');
            setEditId(null);

        } catch (error) {
            toast.error('Error saving activity. Please try again.');
        }
    };

    // Edit Activity
    const handleEdit = (id) => {
        const activity = activities.find(activity => activity.id === id);

        if (activity) {
            setName(activity.name);
            setValue(activity.value);
            setEditId(id);
        }
    };

    // Delete Activity
    const handleDelete = async (id) => {
        try {
            // Filter out the activity to delete
            const updatedActivities = activities.filter(activity => activity.id !== id);

            // Update state
            setActivities(updatedActivities);
            setProfile({ ...profile, activity: updatedActivities });

            // Call API to update profile with the new activity list
            await axios.put(`https://node-setup-project-production.up.railway.app/api/profile/${profile._id}`, { ...profile, activity: updatedActivities });

            // Provide feedback to the user
            toast.success('Data deleted successfully!');
        } catch (error) {
            toast.error('Error deleting activity. Please try again.');
        }
    };

    return (
        <div className="container mt-4">
            <h4 className="mb-4">Activity Manager</h4>
            <div className="mb-4">
                <div className="mb-3">
                    <label className="form-label">Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter activity name"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Value:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Enter activity value"
                    />
                </div>
                <button
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    {editId !== null ? 'Update' : 'Add'} Activity
                </button>
            </div>

            <ul className="list-group">
                {activities?.map(activity => (
                    <li key={activity.id} className="list-group-item d-flex justify-content-between align-items-center">
                        {activity.name} - {activity.value}
                        <div>
                            <button
                                className="btn btn-warning btn-sm me-2"
                                onClick={() => handleEdit(activity.id)}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(activity.id)}
                            >
                                Delete
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ActivityManager;
