import React from 'react';
import Slider from "react-slick";
import ts1 from '../../images/testimonial/1.jpg'
import ts2 from '../../images/testimonial/2.jpg'
import ts3 from '../../images/testimonial/3.jpg'
import quote from '../../images/testimonial/quote.png'


const testimonial = [
    {
        id: '01',
        tImg: ts1,
        Des: "Purus eget consectur massa amet. Hactor bodiam suspendie faucibus posuere dignissim amet to atthe. Vitaer of sollicitudin mauris erat odio maecenas mattis praesent.Eget vitaoe magna condimentum tristique scelerisque",
        Title: 'William Henry',
        Sub: "WP Developer",
    },
    {
        id: '02',
        tImg: ts2,
        Des: "Purus eget consectur massa amet. Hactor bodiam suspendie faucibus posuere dignissim amet to atthe. Vitaer of sollicitudin mauris erat odio maecenas mattis praesent.Eget vitaoe magna condimentum tristique scelerisque",
        Title: 'Harry Abraham',
        Sub: "SCG First Company",
    },
    {
        id: '03',
        tImg: ts3,
        Des: "Purus eget consectur massa amet. Hactor bodiam suspendie faucibus posuere dignissim amet to atthe. Vitaer of sollicitudin mauris erat odio maecenas mattis praesent.Eget vitaoe magna condimentum tristique scelerisque",
        Title: 'Benjir Walton',
        Sub: "Merketer",
    }
]

const Testimonial = ({ client, Tital }) => {

    const settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        fade: true,
    };


    return (
        <section className="testimonial-section" id="Testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">

                            <h2>{Tital?.filter(heding => heding.type === 'Testimonial')[0]["tital"]}</h2>
                            <h3>{Tital?.filter(heding => heding.type === 'Testimonial')[0]["subtital"]}</h3>
                        </div>
                    </div>
                </div>
                <div className="testimonial-wrap testimonial-slider">
                    <Slider {...settings}>
                        {client.filter((el, o) => {
                            return el.type === "client"
                        }).map((testimonial, tsm) => (

                            <div className="testimonial-card" key={tsm}>
                                <div className="top-content">
                                    <div className="image">
                                        <img src={testimonial.pImg} alt="" style={{
                                            width: "100%", height: "100%",
                                            objectFit: 'contain'
                                        }} />
                                    </div>
                                    <div className="text">
                                        <h2>{testimonial.title}</h2>
                                        <li><b>{new Date(testimonial?.createdAt)?.toLocaleString()}</b></li>
                                    </div>
                                </div>
                                <h3>“{testimonial.description}”</h3>
                                <div className="icon">
                                    <img src={quote} alt="quote" />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;