// src/components/CreateHeding.js
import axios from 'axios';
import React, { useState } from 'react';

const CreateHeding = () => {
    const [tital, setTital] = useState('');
    const [subtital, setSubtital] = useState('');
    const [type, setType] = useState('work');
    const [error, setError] = useState(null);

    const getHedings = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/heding/hedings', {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.error || 'Error fetching hedings');
        }
    };
    const fetchHedings = async () => {
        try {
            const data = await getHedings();
        } catch (error) {
            setError(error.message);
        }
    };

    const createHeding = async (hedingData) => {
        try {
            const response = await axios.post('https://node-setup-project-production.up.railway.app/api/heding/hedings', hedingData, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
            fetchHedings();
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.error || 'Error creating heding');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newHeding = { tital, subtital, type };
            await createHeding(newHeding);
            setTital('');
            setSubtital('');
            setType('work');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container mt-3">
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit} className="bg-light p-4 rounded border">
                <div className="mb-3">
                    <label htmlFor="tital" className="form-label">Tital:</label>
                    <input
                        id="tital"
                        type="text"
                        className="form-control"
                        value={tital}
                        onChange={(e) => setTital(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="subtital" className="form-label">Subtital:</label>
                    <input
                        id="subtital"
                        type="text"
                        className="form-control"
                        value={subtital}
                        onChange={(e) => setSubtital(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type:</label>
                    <select
                        id="type"
                        className="form-select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required
                    >
                        <option value="Profile">Profile</option>
                        <option value="service">service</option>
                        <option value="Testimonial">Testimonial</option>
                        <option value="Portfolio">Portfolio</option>
                        <option value="FunFact">FunFact</option>
                        <option value="Blog">Blog</option>
                        <option value="Contact">Contact</option>
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Create Heding</button>
            </form>
        </div>
    );
};

export default CreateHeding;
