import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Grid } from '@mui/material';
import axios from 'axios';

import blog3 from '../../images/blog-details/comments-author/img-1.jpg';

const BlogSingle = ({ maxWidth, open, onClose, title, bImg, create_at, author, author1, allblog, _Id }) => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [active, setActive] = useState(1);
    const [email, setEmail] = useState('');
    const [RepID, setRepID] = useState(null);
    const fetchComments = async () => {
        try {
            const response = await axios.get(`https://node-setup-project-production.up.railway.app/api/comment/comments/${_Id}`);
            setComments(response.data);
        } catch (error) {
            console.error('Failed to fetch comments', error);
        }
    };

    // Fetch comments when component mounts
    useEffect(() => {

        if (_Id) {
            fetchComments();
        }
    }, [_Id]);

    const submitHandler = async (e) => {
        e.preventDefault();

        const commentData = {
            blogId: _Id,
            author: name,
            comment: comment,
            email: email,
            RepID: RepID,
        };

        try {
            const response = RepID === null
                ? await axios.post('https://node-setup-project-production.up.railway.app/api/comment/comments', commentData)
                : await axios.put('https://node-setup-project-production.up.railway.app/api/comment/comments', commentData);

            setComments([...comments, response.data]);  // Add the new comment/reply to the state
            setComment('');
            setName('');
            setEmail('');
            setRepID(null);  // Reset RepID after a reply is posted
            fetchComments();
            alert('Comment posted successfully!');
        } catch (error) {
            console.error('Error posting comment:', error);
            alert('Failed to post comment. Please try again.');
        }
    };

    const renderReplies = (replies, index1) => {
        return replies.map((reply, index) => (
            <>

                <li key={index} className={`commen  d-md-flex`}>
                    <div className="p-3">
                        <div className={`comment-image`}><img src={`/${2}.jpg`} alt="" style={{ borderRadius: "50%" }} width={100} height={100} /></div>
                    </div>
                    <div className={`p-3`}>
                        <div className="">
                            <div className="comments-meta">
                                <h4>{reply.author.slice(0, 20)}.. <span className="comments-date">{new Date(reply.createdAt).toLocaleDateString()}</span></h4>
                            </div>
                            <div className="comment-area">
                                <p>{reply.comment.slice(0, 60)}...</p>
                                <div className="comments-reply" onClick={() => setRepID(reply._id)}>
                                    <Link className="comment-reply-link"><span>Reply</span></Link>
                                </div>
                                {/* Render nested replies */}

                            </div>
                        </div>
                    </div>
                </li>
                {reply.children && reply.children.length > 0 && (
                    <ul className="children">
                        {renderReplies(reply.children, index)}
                    </ul>
                )}
            </>
        ));
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
                data-lenis-prevent
            >
                <Grid className="modalBody modal-body py-3 p-md-4">
                    <div className="modal-close-btn">
                        <button onClick={onClose}><i className='ti-close'></i></button>
                    </div>
                    <section className="wpo-blog-single-section">
                        <div className="container">
                            <div className="row">
                                <div className='col col-lg-12 col-12'>
                                    <div className="wpo-blog-content">
                                        <div className="post format-standard-image">
                                            <div className="entry-media">
                                                <img src={bImg} alt="" />
                                            </div>
                                            <div className="entry-meta">
                                                <ul>
                                                    <li><i className="fi flaticon-calendar"></i> {create_at}</li>
                                                </ul>
                                            </div>
                                            <h2>{title}</h2>
                                            <p>{author}</p>
                                            {author1 && author1.length > 5 && (
                                                <blockquote>
                                                    {author1}
                                                </blockquote>
                                            )}

                                            <h2>All Blogs</h2>
                                            <div className="gallery">
                                                <div className='row w-100'>
                                                    {allblog.slice(0, 2).map((el, i) => (
                                                        <div key={i} className='col-12 col-md-6 p-2'>
                                                            <img src={el['pImg']} alt="" className='img-fluid' />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {comments.slice(active - 1, active).map((comment, index) => {
                                                const data = comments.filter(el => el.RepID === undefined);
                                                if (comment.RepID === undefined)
                                                    return (
                                                        <>
                                                            <div class="author-box"><div class="author-avatar"><a href="/" target="_blank"><img src={`/${1}.jpg`} alt="" /></a></div>
                                                                <div class="author-content"><a class="author-name" href="/">Author: {comment.author}</a><p> {comment.comment}</p><div class="socials"><ul class="social-link"><li><a href="/"><i class="ti-facebook"></i></a></li><li><a href="/"><i class="ti-twitter-alt"></i></a></li><li><a href="/"><i class="ti-linkedin"></i></a></li><li><a href="/"><i class="ti-instagram"></i></a></li></ul></div></div></div>
                                                            <div class="more-posts"><div class="previous-post" onClick={() => {
                                                                setActive(active > 1 ? 1 : active - 1)
                                                            }}>
                                                                <a>
                                                                    <span class="post-control-link">Previous Post</span>
                                                                </a>
                                                            </div>
                                                                <div class="next-post" onClick={() => {
                                                                    setActive(active + 1)
                                                                }}>
                                                                    <a ><span class="post-control-link">Next Post</span>
                                                                    </a></div></div>

                                                        </>
                                                    )
                                            })}

                                            <div className="comments-area">
                                                <div className="comments-section">
                                                    <h3 className="comments-title">Comments</h3>
                                                    <ol className="comments">
                                                        {comments.map((comment, index) => {
                                                            if (comment.RepID === undefined)
                                                                return (
                                                                    <>
                                                                        <li key={index} className={`comment  d-md-flex`}>
                                                                            <div className="p-3">
                                                                                <div className={`comment-image`}><img src={`/${1}.jpg`} alt="" style={{ borderRadius: "50%" }} /></div>
                                                                            </div>
                                                                            <div className={`p-3`}>
                                                                                <div className="">
                                                                                    <div className="comments-meta">
                                                                                        <h4>{comment.author} <span className="comments-date">{new Date(comment.createdAt).toLocaleDateString()}</span></h4>
                                                                                    </div>
                                                                                    <div className="comment-area">
                                                                                        <p>{comment.comment}</p>
                                                                                        <div className="comments-reply" onClick={() => setRepID(comment._id)}>
                                                                                            <Link className="comment-reply-link"><span>Reply</span></Link>
                                                                                        </div>
                                                                                        {/* Render nested replies */}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        {comment.children && comment.children.length > 0 && (
                                                                            <ul className="children">
                                                                                {renderReplies(comment.children, index)}
                                                                            </ul>
                                                                        )}
                                                                    </>
                                                                )
                                                        })}
                                                    </ol>
                                                </div>
                                                <div className="comment-respond">
                                                    <h3 className="comment-reply-title">Post Comments</h3>
                                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                                        <div className="form-textarea">
                                                            <textarea
                                                                id="comment"
                                                                placeholder="Write Your Comments..."
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className="form-inputs">
                                                            <input
                                                                placeholder="Name"
                                                                type="text"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                            />
                                                            <input
                                                                placeholder="Email"
                                                                type="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-submit">
                                                            <input id="submit" value="Post Comment" type="submit" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Grid>
            </Dialog>
        </Fragment>
    );
}

export default BlogSingle;
