import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo1 from '../../images/logo.png';
import logo2 from '../../images/logo-2.png';

const Header = ({ }) => {
    const [menuActive, setMenuState] = useState(false);

    return (
        <header className="header-section" id="sticky">
            <div className="container">
                <div className="hrader-topbar">
                    <div className="row justify-content-between">
                        <div className="col-lg-3 col-9">
                            <Link to="About" spy={true} smooth={true} duration={500} className="logo">
                                <img className="logo-on" src={logo1} alt="logo" />
                                <img className="logo-two" src={logo2} alt="logo2" />
                            </Link>
                        </div>
                        <div className="col-lg-3 col-3">
                            <div className="menu-open" onClick={() => setMenuState(!menuActive)}>
                                <i className="ti-align-justify" id="bar_close"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`side-menu ${menuActive ? "open-mobilemenu" : ""}`} id="menu_wrap">
                    <div className="menu-close">
                        <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                    </div>
                    <ul>
                        <li >
                            <Link to="About" spy={true} smooth={true} duration={500} offset={-120}>About</Link>
                        </li>
                        <li >
                            <Link to="service" spy={true} smooth={true} duration={500} offset={-20}>service</Link>
                        </li>
                        <li >
                            <Link to="Testimonial" spy={true} smooth={true} duration={500} offset={-20}>Testimonial</Link>
                        </li>
                        <li >
                            <Link to="Portfolio" spy={true} smooth={true} duration={500} offset={-20}>Portfolio</Link>
                        </li>
                        <li >
                            <Link to="FunFact" spy={true} smooth={true} duration={500} offset={-20}>FunFact</Link>
                        </li>
                        <li >
                            <Link to="Blog" spy={true} smooth={true} duration={500} offset={-20}>Blog</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;
