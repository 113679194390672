import swIcon from '../images/service/1.svg'
import dswIcon from '../images/service/dark/1.svg'
import swImgS1 from '../images/service-single/web/img-1.jpg'
import swImgS2 from '../images/service-single/web/img-2.jpg'
import swImgS3 from '../images/service-single/web/img-3.jpg'

import saIcon from '../images/service/2.svg'
import dsaIcon from '../images/service/dark/2.svg'
import saImgS1 from '../images/service-single/app/img-1.jpg'
import saImgS2 from '../images/service-single/app/img-2.jpg'
import saImgS3 from '../images/service-single/app/img-3.jpg'

import sWaIcon from '../images/service/3.svg'
import dsWaIcon from '../images/service/dark/3.svg'
import sWaImgS1 from '../images/service-single/webapp/img-1.jpg'
import sWaImgS2 from '../images/service-single/webapp/img-2.jpg'
import sWaImgS3 from '../images/service-single/webapp/img-3.jpg'

import sbIcon from '../images/service/4.svg'
import dsbIcon from '../images/service/dark/4.svg'
import sbImgS1 from '../images/service-single/brand/img-1.jpg'
import sbImgS2 from '../images/service-single/brand/img-2.jpg'
import sbImgS3 from '../images/service-single/brand/img-3.jpg'

import sGIcon from '../images/service/5.svg'
import dsGIcon from '../images/service/dark/5.svg'
import sGImgS1 from '../images/service-single/graphic/img-1.jpg'
import sGImgS2 from '../images/service-single/graphic/img-2.jpg'
import sGImgS3 from '../images/service-single/graphic/img-3.jpg'

import sUxIcon from '../images/service/6.svg'
import dsUxIcon from '../images/service/dark/6.svg'
import sUxImgS1 from '../images/service-single/ux/img-1.jpg'
import sUxImgS2 from '../images/service-single/ux/img-2.jpg'
import sUxImgS3 from '../images/service-single/ux/img-3.jpg'



const Services = [
    {
        Id: '1',
        sIcon:swIcon,
        dsIcon:dswIcon,
        sImgS:swImgS1,
        sTitle: 'Website Design',
        description:'I have complete 125+ website design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:swImgS2,
        ssImg2:swImgS3,
    },
    {
        Id: '2',
        sIcon:saIcon,
        dsIcon:dsaIcon,
        sImgS:saImgS1,
        sTitle: 'Mobile App Design',
        description:'I have complete 120+ App Design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:saImgS2,
        ssImg2:saImgS3,
    },
    {
        Id: '3',
        sIcon:sWaIcon,
        dsIcon:dsWaIcon,
        sImgS:sWaImgS1,
        sTitle: 'Web App Design',
        description:'I have complete 130+ Web Design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sWaImgS2,
        ssImg2:sWaImgS3,
    },
    {
        Id: '4',
        sIcon:sbIcon,
        dsIcon:dsbIcon,
        sImgS:sbImgS1,
        sTitle: 'Brand Design',
        description:'I have complete 180+ Brand Design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sbImgS2,
        ssImg2:sbImgS3,
    },
    {
        Id: '5',
        sIcon:sGIcon,
        dsIcon:dsGIcon,
        sImgS:sGImgS1,
        sTitle: 'Graphic Design',
        description:'I have complete 200+ Graphic Design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sGImgS2,
        ssImg2:sGImgS3,
    },
    {
        Id: '6',
        sIcon:sUxIcon,
        dsIcon:dsUxIcon,
        sImgS:sUxImgS1,
        sTitle: 'UX/UI Design',
        description:'I have complete 200+ UX/UI Design for my clients.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sUxImgS2,
        ssImg2:sUxImgS3,
    },
]    

export default Services;