
import React, { Fragment } from 'react';
import { Dialog, Grid, } from '@mui/material'
import Contact from './contact';
import RelatedProject from './related';


const ProjectSingle = ({ maxWidth, open, onClose, profile, dec, title, pImg, ps1img, psub1img1, psub1img2, }) => {

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
                data-lenis-prevent
            >
                <Grid className="modalBody modal-body project-modal p-3">
                    <div className="modal-close-btn">
                        <button onClick={onClose}><i className='ti-close'></i></button>
                    </div>
                    <div className="wpo-project-single-area">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-12">
                                    <div className="wpo-project-single-wrap">
                                        <div className="wpo-project-single-item">
                                            <div className="row mb-8">
                                                <div className="col-lg-7">
                                                    <div className="wpo-project-single-title">
                                                        <h3>{title} Project</h3>
                                                    </div>
                                                    <p>{dec}</p>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="wpo-project-single-content-des-right">
                                                        <img src={pImg} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpo-project-single-item list-widget">
                                            <div className="row">
                                                <div className="grid col-lg-6 mb-8">
                                                    <div className="wpo-project-single-title">
                                                        <h3>Resieved goals</h3>
                                                    </div>
                                                    <ul>
                                                        <li>Non saed velit dictum quam risus pharetra esta.</li>
                                                        <li>Id risus pharetra est, at rhoncus, nec ullamcorper tincidunt.</li>
                                                        <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                                                        <li>Massa volutpat odio facilisis purus sit elementum.</li>
                                                    </ul>
                                                </div>
                                                <div className="grid col-lg-6 list-widget-s">
                                                    <div className="wpo-project-single-title">
                                                        <h3>Results</h3>
                                                    </div>
                                                    <ul>
                                                        <li>Mauris dignissim blandit cursus imperdiet accumsan lorem.</li>
                                                        <li>Nam id in non sed cras purus nunc et.</li>
                                                        <li>Mauris orci, cursus nisl odio est adipiscing gravida magna eget.</li>
                                                        <li>Quis mauris vel felis convallis nulla dignissim.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <RelatedProject profile={profile} />
                                        <div className="wpo-project-single-item">
                                            <div className="wpo-project-contact-area">
                                                <div className="wpo-contact-title">
                                                    <h2 className='heading-font font-bold text-3xl md:text-[25px] sm:text-[22px] mb-[15px]'>Have project in mind? Let's discuss</h2>
                                                    <p>Get in touch with us to see how we can help you with your project</p>
                                                </div>
                                                <div className="wpo-contact-form-area">
                                                    <Contact />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ProjectSingle;

