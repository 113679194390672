import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { storage } from './firebase'; // Ensure firebase is configured correctly
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Header from '../../components/header/Header2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateHeding from './CreateHeding';
import HedingList from './HedingList';
import ActivityManager from './ActivityManager';
import FunFactManager from './FunFactManager';
const Loader = () => (
    <div className="loader">
        <div className="bouncing-loader">
            <div className="bouncing-ball"></div>
            <div className="bouncing-ball"></div>
            <div className="bouncing-ball"></div>
        </div>
    </div>
);

const Dashboard = ({ props }) => {
    const [profile, setProfile] = useState({ name: '', img: '', dec: '', tital: '', tital1: "", number: '', email: '', WD: '', AD: '', DD: '', GD: '', subtital: "", tital: "" });
    const [workdata, setWorkData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [activediv, setactivediv] = useState("About");
    const [profileId, setProfileId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState('');
    const [passcode, setPasscode] = useState('');
    const [isLoginVisible, setIsLoginVisible] = useState(true);
    const [filter, setFilter] = useState('all'); // State to keep track of selected filter
    const [work, setWork] = useState({ pImg: '', ps1img: '', title: '', filterName: '', description: '', subtital: "", tital: "", type: 'work' });
    const [blog, setBlog] = useState({ pImg: '', ps1img: '', title: '', description: '', description1: "", subtital: "", tital: "", type: 'blog' });
    const [clint, setClint] = useState({ pImg: '', ps1img: '', title: '', type: 'client' });
    const [service, setService] = useState({ pImg: '', description: '', title: '', type: 'description', subtital: "", tital: "", type: 'service' });
    const [image, setImage] = useState(null);
    const [profilepageshow, setProfilePageShow] = useState(true);

    useEffect(() => {
        fetchProfile();
        fetchWork();
    }, [isEditing]);

    const fetchProfile = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/profile');
            if (response.data) {
                setProfile(response.data[0]);
                setProfileId(response.data[0]._id);
                setIsEditing(true);
            }
        } catch (error) {
            toast.error('Error fetching profile data.');
        }
    };

    const fetchWork = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/works');
            if (response.data) {
                setWorkData(response.data);
            }
        } catch (error) {
            toast.error('Error fetching work data.');
        }
    };

    const handleLogin = (e) => {
        e.preventDefault();
        if (passcode === '123123' && userId === 'admin') {
            setIsLoggedIn(true);
            setIsLoginVisible(false);
        } else {
            toast.error('Invalid passcode.');
        }
    };

    const handleImageUpload = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            const imageRef = ref(storage, `${e.target.files[0].name + Math.floor(Math.random() * 7)}`);
            uploadBytes(imageRef, e.target.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setProfile((prevState) => ({ ...prevState, img: url }));
                    toast.success("Image uploaded successfully");
                });
            });
        }
    };


    const handleImageUpload1 = (e, imgField) => {
        const file = e.target.files[0];
        if (!file) return;
        const storageRef = ref(storage, `images/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                setWork((prevState) => ({ ...prevState, [imgField]: downloadURL }));
                toast.success(`${imgField} uploaded successfully!`);
            });
        });
    };
    const handleImageUpload2 = (e, imgField) => {
        const file = e.target.files[0];
        if (!file) return;
        const storageRef = ref(storage, `images/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                setWork((prevState) => ({ ...prevState, [imgField]: downloadURL }));
                toast.success(`${imgField} uploaded successfully!`);
            });
        });
    };
    const handleImageUpload3 = (e, imgField) => {
        const file = e.target.files[0];
        if (!file) return;
        const storageRef = ref(storage, `images/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                setBlog((prevState) => ({ ...prevState, [imgField]: downloadURL }));
                toast.success(`${imgField} uploaded successfully!`);
            });
        });
    };
    const handleImageUpload4 = (e, imgField) => {
        const file = e.target.files[0];
        if (!file) return;
        const storageRef = ref(storage, `images/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                setService((prevState) => ({ ...prevState, [imgField]: downloadURL }));
                toast.success(`${imgField} uploaded successfully!`);
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await axios.put(`https://node-setup-project-production.up.railway.app/api/profile/${profileId}`, profile);
                toast.success('Profile updated successfully!');
            } else {
                await axios.post('https://node-setup-project-production.up.railway.app/api/profile', profile);
                toast.success('Profile created successfully!');
            }
            setProfile({ name: '', img: '', dec: '', tital: '', number: '', email: '', WD: '', AD: '', DD: '', GD: '', subtital: "", tital: "" });
            setIsEditing(false);
        } catch (error) {
            toast.error('Error saving profile. Please try again.');
        }
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/works', work);
            toast.success('Work created successfully!');
            setWork({ pImg: '', ps1img: '', title: '', filterName: '', description: '', type: 'work' });
            fetchWork();
        } catch (error) {
            toast.error('Error creating work. Please try again.');
        }
    };
    const handleSubmit2 = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/works', work);
            toast.success('Work created successfully!');
            setClint({ pImg: '', ps1img: '', title: '', filterName: '', description: '', type: 'client' });
            fetchWork();
        } catch (error) {
            toast.error('Error creating work. Please try again.');
        }
    };
    const handleSubmit3 = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/works', blog);
            toast.success('Work created successfully!');
            setBlog({ pImg: '', ps1img: '', title: '', filterName: '', description: '', type: 'blog' });
            fetchWork();
        } catch (error) {
            toast.error('Error creating work. Please try again.');
        }
    };
    const handleSubmit4 = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://node-setup-project-production.up.railway.app/api/works', service);
            toast.success('Work created successfully!');
            setService({ pImg: '', ps1img: '', title: '', filterName: '', description: '', type: 'service' });
            fetchWork();
        } catch (error) {
            toast.error('Error creating work. Please try again.');
        }
    };

    const deleteWork = async (id) => {
        try {
            await axios.delete(`https://node-setup-project-production.up.railway.app/api/works/${id}`);
            toast.success('Work deleted successfully!');
            fetchWork(); // Refresh work data after deletion
        } catch (error) {
            toast.error('Error deleting work.');
        }
    };

    const filteredWorkdata = filter === 'all' ? workdata : workdata.filter(work => work.type === filter);

    return (
        <>
            {!isLoginVisible ? (
                <div className="container mt-5">
                    <h2 className="my-4">Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group py-1">
                            <label htmlFor="userId">User ID</label>
                            <input
                                type="text"
                                className="form-control"
                                id="userId"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group py-1">
                            <label htmlFor="passcode">Passcode</label>
                            <input
                                type="password"
                                className="form-control"
                                id="passcode"
                                value={passcode}
                                onChange={(e) => setPasscode(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Login</button>
                    </form>
                </div>
            ) : (
                <div className="dashboard-container">
                    <Header activediv={activediv} setactivediv={setactivediv} />
                    <div className="page-content">
                        <div className="card mb-4" id='About'>
                            <div className="card-header">
                                <h3>Profile Information</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group py-1">
                                        <label htmlFor="name">main tital</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="tital1"
                                            value={profile.tital1}
                                            onChange={(e) => setProfile({ ...profile, tital1: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            value={profile.name}
                                            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="img">Profile Image</label>
                                        <input type="file" className="form-control" id="img" onChange={handleImageUpload} />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="dec">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="dec"
                                            value={profile.dec}
                                            onChange={(e) => setProfile({ ...profile, dec: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="tital">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="tital"
                                            name="tital"
                                            placeholder="Enter title"
                                            value={profile.tital}
                                            onChange={(e) => setProfile({ ...profile, tital: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="number">Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="number"
                                            name="number"
                                            placeholder="Enter number"
                                            value={profile.number}
                                            onChange={(e) => setProfile({ ...profile, number: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={profile.email}
                                            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                                            required
                                        />
                                    </div>
                                    {/* <div className="form-group py-1">
                                        <label htmlFor="WD">Website Design
                                            %</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="WD"
                                            name="WD"
                                            placeholder="Enter Website Design %"
                                            value={profile.WD}
                                            onChange={(e) => setProfile({ ...profile, WD: e.target.value })}

                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="AD">App Design
                                            %</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="AD"
                                            name="AD"
                                            placeholder="Enter App Design
 %"
                                            value={profile.AD}
                                            onChange={(e) => setProfile({ ...profile, AD: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="DD">Dashboard Design
                                            %</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="DD"
                                            name="DD"
                                            placeholder="Enter Dashboard Design
 %"
                                            value={profile.DD}
                                            onChange={(e) => setProfile({ ...profile, DD: e.target.value })}

                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="GD">Graphic Design
                                            %</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="GD"
                                            name="GD"
                                            placeholder="Enter Graphic Design
 %"
                                            value={profile.GD}
                                            onChange={(e) => setProfile({ ...profile, GD: e.target.value })}

                                            required
                                        />
                                    </div> */}
                                    <div className="form-group py-1">
                                        <label htmlFor="Twitter">Twitter</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Twitter"
                                            name="Twitter"
                                            placeholder="Enter Twitter URL"
                                            value={profile.Twitter}
                                            onChange={(e) => setProfile({ ...profile, Twitter: e.target.value })}
                                        />
                                    </div>


                                    <div className="form-group py-1">
                                        <label htmlFor="facebook">Facebook</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="facebook"
                                            name="facebook"
                                            placeholder="Enter Facebook URL"
                                            value={profile.facebook}
                                            onChange={(e) => setProfile({ ...profile, facebook: e.target.value })}
                                        />
                                    </div>

                                    <div className="form-group py-1">
                                        <label htmlFor="instagram">Instagram</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="instagram"
                                            name="instagram"
                                            placeholder="Enter Instagram URL"
                                            value={profile.instagram}
                                            onChange={(e) => setProfile({ ...profile, instagram: e.target.value })}
                                        />
                                    </div>
                                    {/* Add more fields as necessary */}
                                    <button type="submit" className="btn btn-primary">{isEditing ? 'Update' : 'Save'}</button>
                                </form>
                            </div>
                            <ActivityManager setProfile={setProfile} profile={profile} />
                            <FunFactManager setProfile={setProfile} profile={profile} />
                        </div>
                        <div className="card mb-4" id='service'>
                            <div className="card-header">
                                <h3>Manage Title</h3>
                            </div>
                            <CreateHeding />
                            <HedingList />
                        </div>
                        <div className="card mb-4" id='service'>
                            <div className="card-header">
                                <h3>Manage service</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit4}>

                                    <div className="form-group py-1">
                                        <label htmlFor="pImg">Project Image</label>
                                        <input type="file" className="form-control" id="pImg" onChange={(e) => handleImageUpload4(e, 'pImg')} />
                                        <img src={service.pImg} className='img-fluid m-2' width={125} height={125} />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={service.title}
                                            onChange={(e) => setService({ ...service, title: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            value={service.description}
                                            onChange={(e) => setService({ ...service, description: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save Service</button>
                                </form>

                                <div className="mt-4">
                                    <h4>Existing service</h4>
                                    <ul className="list-group">
                                        {filteredWorkdata.map((item) => {
                                            if (item['type'] == "service") {
                                                return (
                                                    <li key={item._id} className="list-group-item d-flex justify-content-between align-items-center">
                                                        {item.title}
                                                        <div>
                                                            <button
                                                                className="btn btn-warning btn-sm me-2"
                                                                onClick={() => {
                                                                    setService({
                                                                        title: item.title,
                                                                        description: item.description,
                                                                        pImg: item.pImg, type: 'service',
                                                                        editingId: item._id, // Set the ID of the blog being edited
                                                                    });
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => deleteWork(item._id)}>Delete</button>
                                                        </div>                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4" id='Testimonial'>
                            <div className="card-header">
                                <h3>Manage Testimonial</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit2}>
                                    <div className="form-group py-1">
                                        <label htmlFor="pImg">Project Image</label>
                                        <input type="file" className="form-control" id="pImg" onChange={(e) => handleImageUpload2(e, 'pImg')} />
                                        <img src={clint.pImg} className='img-fluid m-2' width={125} height={125} />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={clint.title}
                                            onChange={(e) => setClint({ ...clint, title: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            value={clint.description}
                                            onChange={(e) => setClint({ ...clint, description: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save Clints</button>
                                </form>

                                <div className="mt-4">
                                    <h4>Existing Testimonial</h4>
                                    <ul className="list-group">
                                        {filteredWorkdata.map((item) => {
                                            if (item['type'] == "client") {
                                                return (
                                                    <li key={item._id} className="list-group-item d-flex justify-content-between align-items-center">
                                                        {item.title}
                                                        <div>
                                                            <button
                                                                className="btn btn-warning btn-sm me-2"
                                                                onClick={() => {
                                                                    setClint({
                                                                        title: item.title,
                                                                        description: item.description,
                                                                        pImg: item.pImg, type: 'client',
                                                                        editingId: item._id, // Set the ID of the blog being edited
                                                                    });
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => deleteWork(item._id)}>Delete</button>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card  mb-4" id='Portfolio'>
                            <div className="card-header">
                                <h3>Manage Portfolio</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit1}>
                                    <div className="form-group py-1">
                                        <label htmlFor="pImg">Project Image</label>
                                        <input type="file" className="form-control" id="pImg" onChange={(e) => handleImageUpload1(e, 'pImg')} />
                                        <img src={work.pImg} className='img-fluid m-2' width={125} height={125} />

                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={work.title}
                                            onChange={(e) => setWork({ ...work, title: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            value={work.description}
                                            onChange={(e) => setWork({ ...work, description: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="filterName">Filter Name</label>
                                        <select
                                            name="filterName"
                                            id="filterName"
                                            className="form-control"
                                            value={work.filterName}
                                            onChange={(e) => setWork({ ...work, filterName: e.target.value })}
                                            required
                                        >
                                            <option value=""></option>
                                            <option value="all website">all website</option>
                                            <option value="all app">all app</option>
                                            <option value="all graphics">all graphics</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save Work</button>
                                </form>

                                <div className="mt-4">
                                    <h4>Existing Portfolio</h4>
                                    <ul className="list-group">
                                        {filteredWorkdata.map((item) => {
                                            if (item['type'] == "work") {
                                                return (
                                                    <li key={item._id} className="list-group-item d-flex justify-content-between align-items-center">
                                                        {item.title}
                                                        <div>
                                                            <button
                                                                className="btn btn-warning btn-sm me-2"
                                                                onClick={() => {
                                                                    setWork({
                                                                        title: item.title,
                                                                        description: item.description,
                                                                        filterName: item.filterName,
                                                                        pImg: item.pImg, type: 'work',
                                                                        editingId: item._id, // Set the ID of the blog being edited
                                                                    });
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => deleteWork(item._id)}>Delete</button>
                                                        </div>   </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card mb-4" id="FunFact">
                            <div className="card-header">
                                <h3>FunFact</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group py-1">
                                        <label htmlFor="CompleteProjects">Complete Projects</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="CompleteProjects"
                                            name="CompleteProjects"
                                            placeholder="Enter Complete Projects"
                                            value={profile.CompleteProjects}
                                            onChange={(e) => setProfile({ ...profile, [e.target['name']]: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="form-group py-1">
                                        <label htmlFor="AverageRating">Average Rating</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="AverageRating"
                                            name="AverageRating"
                                            placeholder="Enter Average Rating"
                                            value={profile.AverageRating}
                                            onChange={(e) => setProfile({ ...profile, [e.target['name']]: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="form-group py-1">
                                        <label htmlFor="HappyClients">Happy Clients</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="HappyClients"
                                            name="HappyClients"
                                            placeholder="Enter Happy Clients"
                                            value={profile.HappyClients}
                                            onChange={(e) => setProfile({ ...profile, [e.target['name']]: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="form-group py-1">
                                        <label htmlFor="WinningAwards">Winning Awards</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="WinningAwards"
                                            name="WinningAwards"
                                            placeholder="Enter Winning Awards"
                                            value={profile.WinningAwards}
                                            onChange={(e) => setProfile({ ...profile, [e.target['name']]: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary my-4">Create Profile</button>
                                </form>
                            </div>
                        </div> */}
                        <div className="card mb-4" id='Blog'>
                            <div className="card-header">
                                <h3>Manage blog</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit3}>
                                    <div className="form-group py-1">
                                        <label htmlFor="pImg">Project Image</label>
                                        <input type="file" className="form-control" id="pImg" onChange={(e) => handleImageUpload3(e, 'pImg')} />
                                        <img src={blog.pImg} className='img-fluid m-2' width={125} height={125} />

                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={blog.title}
                                            onChange={(e) => setBlog({ ...blog, title: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            value={blog.description}
                                            onChange={(e) => setBlog({ ...blog, description: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="form-group py-1">
                                        <label htmlFor="description">Description 1</label>
                                        <textarea
                                            className="form-control"
                                            id="Description1"
                                            value={blog.description1}
                                            onChange={(e) => setBlog({ ...blog, description1: e.target.value })}
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save Clints</button>
                                </form>

                                <div className="mt-4">
                                    <h4>Existing blog</h4>
                                    <ul className="list-group">
                                        {filteredWorkdata.map((item) => {
                                            if (item['type'] == "blog") {
                                                return (
                                                    <li key={item._id} className="list-group-item d-flex justify-content-between align-items-center">
                                                        {item.title}
                                                        <div>
                                                            <button
                                                                className="btn btn-warning btn-sm me-2"
                                                                onClick={() => {
                                                                    setBlog({
                                                                        title: item.title,
                                                                        description: item.description,
                                                                        description1: item.description1,
                                                                        pImg: item.pImg, type: 'blog',
                                                                        editingId: item._id, // Set the ID of the blog being edited
                                                                    });
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => deleteWork(item._id)}>Delete</button>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Dashboard;
