import React, { useState } from 'react';
import blogs from '../../api/blogs'
import BlogSingle from "../BlogSingle";


const BlogSection = ({ blog, Tital }) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }

    return (

        <section className="blog-section" id="Blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>{Tital?.filter(heding => heding.type === 'Blog')[0]["tital"]}</h2>
                            <h3>{Tital?.filter(heding => heding.type === 'Blog')[0]["subtital"]}</h3>
                        </div>
                    </div>
                </div>
                <div className="blog-wrap">
                    {blog.filter((el, o) => {
                        return el.type === "blog"
                    }).map((blog, bl) => (
                        <div className="item" key={bl}>
                            <div className="image">
                                <img src={blog.pImg} alt="" />
                            </div>
                            <div className="content">
                                <ul className="date">
                                    <li><b>{new Date(blog?.createdAt)?.toLocaleString()}</b></li>
                                </ul>
                                <h2 onClick={() => handleClickOpen(blog)}>{blog.title}</h2>
                                <h4 onClick={() => handleClickOpen(blog)}>Read More</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <BlogSingle open={open} onClose={handleClose} allblog={blog.filter((el, o) => {
                return el.type === "blog"
            })} _Id={state._id} title={state.title} bImg={state.pImg} create_at={new Date(state?.createdAt)?.toLocaleString()} author={state.description} author1={state.description1} />
        </section>
    );
}

export default BlogSection;