import { React, useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import ProjectSingle from '../ProjectSingle/ProjectSingle';
import Testimonial from '../Testimonial/Testimonial';
import FunFactSection from '../FunFact/FunFact';
import BlogSection from '../BlogSection/BlogSection';
import ServiceSection from '../ServiceSection/ServiceSection';

const ProjectSection = ({ profileData, Tital }) => {
    const [open, setOpen] = useState(false);
    console.log("tital?.filter(heding => heding.type === 'service')", Tital?.filter(heding => heding.type === 'service'));

    const [state, setState] = useState({});
    const [profile, setProfile] = useState([]);
    const [activeFilter, setActiveFilter] = useState('*');

    const containerRef = useRef(null);
    const iso = useRef(null);

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchProfile = async () => {
        try {
            const response = await axios.get('https://node-setup-project-production.up.railway.app/api/works');
            if (response.data) {
                console.log("response.data", response.data);
                setProfile(response.data);
            }
        } catch (error) {
            console.error('Error fetching profile data', error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    useEffect(() => {
        imagesLoaded(containerRef.current, () => {
            iso.current = new Isotope(containerRef.current, {
                itemSelector: '.filter-item',
                layoutMode: 'fitRows',
            });
        });
        return () => {
            iso.current?.destroy();
        };
    }, [profile]);

    const handleFilterClick = (filterValue) => {
        setActiveFilter(filterValue);
        iso.current.arrange({ filter: filterValue === '*' ? '*' : `.${filterValue}` });
    };

    return (
        <>
            <ServiceSection service={profile.filter(el => el.type === "service")} profileData={profileData} />
            <section className="portfolio-section gallery-filters" id="Portfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2>{Tital?.filter(heding => heding.type === 'Portfolio')[0]["tital"]}</h2>
                                <h3>{Tital?.filter(heding => heding.type === 'Portfolio')[0]["subtital"]}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-category">
                        <ul>
                            <li>
                                <button
                                    onClick={() => handleFilterClick('*')}
                                    className={activeFilter === '*' ? 'active' : ''}
                                >
                                    All
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleFilterClick('website')}
                                    className={activeFilter === 'website' ? 'active' : ''}
                                >
                                    Website
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleFilterClick('app')}
                                    className={activeFilter === 'app' ? 'active' : ''}
                                >
                                    Mobile App
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleFilterClick('graphics')}
                                    className={activeFilter === 'graphics' ? 'active' : ''}
                                >
                                    Graphics
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="portfolio-wrap py-5">
                        <div className="row" ref={containerRef}>
                            {profile.filter(el => el.type === "work").map((project, pro) => (
                                <div
                                    className={`filter-item col-lg-6 col-md-6 col-12 grid-item ${project.filterName}`}
                                    key={pro}
                                >
                                    <div className="portfolio_card">
                                        <div className="image">
                                            <div onClick={() => handleClickOpen(project)}>
                                                <img src={project.pImg} alt="" className='' />
                                            </div>
                                            <span> {project.filterName === "all app" ? "Mobile App" : project.filterName === "all graphics" ? "Graphics" : "Website"}</span>
                                        </div>
                                        <h2 onClick={() => handleClickOpen(project)}>{project.title}</h2>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <ProjectSingle
                    open={open}
                    profile={profile}
                    onClose={handleClose}
                    title={state.title}
                    dec={state.description}
                    pImg={state.pImg}
                    ps1img={state.ps1img}
                    psub1img1={state.psub1img1}
                    psub1img2={state.psub1img2}
                    Tital={Tital}
                />
            </section>
            <Testimonial client={profile} Tital={Tital} />
            <FunFactSection profileData={profileData} Tital={Tital} />
            <BlogSection blog={profile} Tital={Tital} />
        </>
    );
}

export default ProjectSection;
