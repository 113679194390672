import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'
import cIcon1 from '../../images/contact/1.png'
import cIcon2 from '../../images/contact/img-1.png'
import cIcon3 from '../../images/contact/2.png'
import cIcon4 from '../../images/contact/img-2.png'
import cIcon5 from '../../images/contact/3.png'
import cIcon6 from '../../images/contact/img-3.png'


const ContactArea = ({ profile, Tital }) => {
    return (
        <section className="contact-section" id="Contact">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>{Tital?.filter(heding => heding.type === 'Contact')[0]["tital"]}</h2>
                            <h3>{Tital?.filter(heding => heding.type === 'Contact')[0]["subtital"]}</h3>
                        </div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon1} alt="icon" className="white-img" />
                            <img src={cIcon2} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Contact Info</h2>
                            <span>244 Royal Mesa, New Jersey </span>
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon3} alt="icon" className="white-img" />
                            <img src={cIcon4} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Email Address</h2>
                            <span>{profile['email']}</span>
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon5} alt="icon" className="white-img" />
                            <img src={cIcon6} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Phone</h2>
                            <span>{profile['number']}</span>
                        </div>
                    </div>
                </div>
                <div className="contact-form-area">
                    <ContactForm profile={profile} />
                </div>
            </div>
        </section>
    )
}

export default ContactArea;