import React from 'react';
import about from '../../images/about-1.jpg'
import about2 from '../../images/about-2.jpg'


const About = ({ profile }) => {

    const circleStyle = profile.activity

    console.log("profile.FunFact", profile);

    return (
        <section className="about-section" id="About">
            <div className="container">
                <div className="about-wrap">
                    <div className="image">
                        <div className="image-inner">
                            <img src={profile.img} alt="abimg" className="white-img" style={{
                                width: "100%", height: "100%",
                                objectFit: 'cover'
                            }} />
                            <img src={profile.img} alt="abimg2" className="dark-img" style={{
                                width: "100%", height: "100%",
                                objectFit: 'cover'
                            }} />
                        </div>
                    </div>
                    <div className="content">
                        <h2>Hello I’m {profile.name}</h2>
                        <h3> {profile.tital}</h3>
                        <p> {profile.dec}</p>
                    </div>
                    <div className="progras-wrap">
                        <div className="row">
                            {circleStyle.map((el) => {
                                return (<>
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="progras-card">
                                            <div className="percent">
                                                <svg>
                                                    <circle cx="70" cy="70" r="59"></circle>
                                                    <circle cx="70" cy="70" r="59" style={{ '--percent': el.value }}></circle>
                                                </svg>
                                                <div className="number">
                                                    <h3>{el.value}<span>%</span></h3>
                                                </div>
                                            </div>
                                            <div className="title">
                                                <h2>{el.name}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </>

                                )
                            })}
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle2}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>{profile.AD}<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>App Design</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle3}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>{profile.DD}<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>Dashboard Design</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg className="animate__rotateOut">
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle4}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>{profile.GD}<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>Graphic Design</h2>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
            <div className="left-text">
                <h2 className="text-outline text-outline-hover" data-text={profile?.tital1 ? profile.tital1.split(" ")[0] : null}>{profile?.tital1 ? profile.tital1.split(" ")[0] : null}</h2>
            </div>
            <div className="right-text">
                <h2 className="text-outline text-outline-hover" data-text={profile?.tital1 ? profile.tital1.split(" ")[1] : null}>{profile?.tital1 ? profile.tital1.split(" ")[1] : null}</h2>
            </div>
        </section>
    )
}

export default About;