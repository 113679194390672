import React, { useState } from 'react';
import Services from '../../api/service'
import ServiceSingle from '../ServiceSingle';

const ServiceSection = ({ service, profileData }) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    return (

        <section className="service-section" id="Services">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2> My Services</h2>
                            <h3>What I Do</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {service.slice(0, 6).map((service, srv) => (
                        <div className="col-xl-6 col-lg-12 col-md-6 col-12" key={srv} onClick={() => {
                            handleClickOpen(service)
                        }}>
                            <div className="service_item">
                                <div className="icon">
                                    <img src={service.pImg} alt="service" className="white-img" />
                                    <img src={service.pImg} alt="service" className="black-img" />
                                </div>
                                <div className="content">
                                    <h2 >{service.title}</h2>
                                    <p>{service.description.slice(0, 20)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ServiceSingle open={open} onClose={handleClose} title={state.title} description={state.description} profileData={profileData} />
        </section>
    );
}

export default ServiceSection;